<template>
  <div class="Login">
    <div class="LoginMethod">
      <div
        v-if="loginMethodConfig === 'password' || loginMethodConfig === 'all'"
        @click="SwitchingMethod('password')"
        class="Method fl"
        :class="{highlight: loginMethod}"
      >
        密码登录
        <span v-show="loginMethod"></span>
      </div>

      <div class="fl cutApart" v-if="loginMethodConfig === 'all'"></div>

      <div
        v-if="loginMethodConfig === 'code' || loginMethodConfig === 'all'"
        @click="SwitchingMethod('Verification')"
        class="Method fl"
        :class="{highlight: !loginMethod}"
      >
        验证码登录
        <span v-show="!loginMethod"></span>
      </div>

      <div class="clear"></div>
    </div>
    <div class="EditingArea">
      <div v-show="loginMethod" class="vanField">
        <ul>
          <li>
            <van-field
              v-model="phone"
              center
              clearable
              :border="false"
              @focus="inputFocus"
              use-button-slot
              :placeholder="'请输入' + placeholderTxt"
            />
          </li>
          <li class="passwordInputWrapper">
            <van-field
              v-model="password"
              class="passwordStyle fl"
              clearable
              :type="isVisible"
              @focus="inputFocus"
              placeholder="请输入登录密码"
              :border="false"
            />

            <div class="VisibleCutApart fl"></div>

            <div class="Visible fr" @click="setVisible">
              <img class="fl" :src="isVisibleImg" alt />
            </div>

            <div class="clear"></div>
          </li>
        </ul>
      </div>
      <div v-show="!loginMethod" class="vanField">
        <ul>
          <li>
            <van-field
              v-model="phone"
              center
              clearable
              :border="false"
              @focus="inputFocus"
              use-button-slot
              :placeholder="'请输入' + placeholderTxt"
            />
          </li>
          <li class="authcodeInputWrapper">
            <van-field
              v-model="Verification"
              class="VerificationStyle fl"
              clearable
              placeholder="请输入验证码"
              :border="false"
            />

            <div
              class="getVerificationStyle fr"
              @click="postAccountGetAuthCode"
              :style="{color:Countdown>0? '#BBBBBB':'#333333'}"
            >{{VerificationStatus}}</div>

            <div class="clear"></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="OperatingSpace">
      <van-button
        round
        type="info"
        :class="phone && protocol ? 'van-button--info' : 'noneVanButton'"
        @click="handleLogin"
      >登录</van-button>
      <div class="protocolStyle">
        <van-checkbox class="myCheckbox" v-model="protocol" label-disabled></van-checkbox>
        <span @click="ClearanceAgreement">我同意</span>
        <a @click="goPage('service-agreement')">《用户协议》</a>
        <span>及</span>
        <a @click="goPage('privacy')">《法律声明及隐私政策》</a>
        <div class="clear"></div>
      </div>

      <div class="botton-btn">
        <!-- <span class="item left" v-if="canRegister" @click="toRouter('/registered')">立即注册</span> -->
        <span class="item left" v-if="canRegister" @click="toRouter('/registered')">立即注册</span>

        <span class="item right" @click="toRouter('/verification')">忘记密码</span>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { Toast } from "vant";
import config from "@/config/url";
import yincang from "./../asset/images/icon-yincang.png";
import xianshi from "./../asset/images/icon-xianshi.png";
import md5 from "js-md5";
const isPhone =
  /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
const isEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const isIdentitycard =
  /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
const agentHost =
  "'agent.saas-h5.testing2.wdeduc.com', 'agent.saas-h5.testing3.wdeduc.com', 'agent.fat.wdeduc.com' , 'agent.uat.wdeduc.com' , 'agent.wdeduc.com', 'localhost:80'";
export default {
  name: "login",
  data() {
    return {
      protocol: false,
      phone: null,
      password: null,
      Verification: null,
      Countdown: 0,
      countdownInterval: null,
      isVisible: "password",
      clientHeight: 0,
      place: "password",
      loginMethodList: ["1", "2", "3", "4", "5"], //1,3验证码，1手机号3邮箱，           2，4，5密码 ，2手机号，4邮箱， 5身份证
      loginMethodConfig: "all",
      logintxt: "手机号码/邮箱/身份证号码",
      canRegister: false,
      haveSavedDetailFlag: true, //判断是否保存过
    };
  },
  computed: {
    loginMethod() {
      return this.place === "password";
    },
    isVisibleImg() {
      return this.isVisible === "password" ? yincang : xianshi;
    },
    VerificationStatus() {
      if (this.Countdown) {
        return `重新获取 ${this.Countdown}s`;
      } else {
        return "发送验证码";
      }
    },
    placeholderTxt() {
      if (
        this.loginMethodConfig === "code" &&
        this.logintxt.includes("身份证")
      ) {
        let index = this.logintxt.lastIndexOf("/");
        return this.logintxt.slice(0, index);
      } else if (
        this.loginMethodConfig === "all" &&
        this.place === "password"
      ) {
        let strToArr = this.logintxt.split("/");
        if (
          !this.loginMethodList.includes("2") &&
          this.logintxt.includes("手机号码")
        ) {
          let index = strToArr.indexOf("手机号码");
          strToArr.splice(index, 1);
        }
        if (
          !this.loginMethodList.includes("4") &&
          this.logintxt.includes("邮箱")
        ) {
          let index = strToArr.indexOf("邮箱");
          strToArr.splice(index, 1);
        }
        return strToArr.join("/");
      } else if (
        this.loginMethodConfig === "all" &&
        this.place === "Verification"
      ) {
        let strToArr = this.logintxt.split("/");
        let ind = strToArr.indexOf("身份证号码");
        if (ind != -1) {
          strToArr.splice(ind, 1);
        }
        if (
          !this.loginMethodList.includes("1") &&
          this.logintxt.includes("手机号码")
        ) {
          let index = strToArr.indexOf("手机号码");
          strToArr.splice(index, 1);
        }
        if (
          !this.loginMethodList.includes("3") &&
          this.logintxt.includes("邮箱")
        ) {
          let index = strToArr.indexOf("邮箱");
          strToArr.splice(index, 1);
        }
        return strToArr.join("/");
      } else {
        return this.logintxt;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.inputFocus);
    const saasId = localStorage.getItem('saasId')
    localStorage.clear();
    localStorage.setItem('saasId', saasId)
    let url = config.confUrl;
    this.configuration(url);
  },
  destroyed() {
    window.removeEventListener("resize", this.inputFocus);
    window.removeEventListener("popstate", this.backChange, false); //false阻止默认事件
  },
  mounted() {
    this.clientHeight = document.documentElement.clientHeight;
    let seach = window.location.search.split("?")[1].split("&");
    let obj = {};
    seach.forEach((item) => {
      let [key, val] = item.split("=");
      obj[key] = val;
    });
    this.phone = obj.phone || sessionStorage.getItem("WdPhone") || null;
    console.log(this.phone);

    window.addEventListener("popstate", this.backChange, false); //false阻止默认事件
  },
  methods: {
    // getLoginMethod(){
    //   if(window.location.port) return
    //   this.$api.usercenter.findLoginType({}).then(res => {
    //     if(res.data.list.length === 0){
    //       this.loginMethodList = ['1','2','3','4','5']
    //     }else{
    //       let list = res.data.list[0].type.split(',').filter((item) => {return !!item})
    //       this.loginMethodList = list
    //     }
    //     this.handleLoginTxt()
    //   })
    // },
    ClearanceAgreement() {
      this.protocol = !this.protocol;
    },
    //   用户协议跳转页面
    goPage(url) {
      this.$router.push(url);
    },
    configuration(url) {
      let params = { url };
      this.$api.usercenter.getConfiguration({ params }).then((res) => {
        if (res.data) {
          let frontendConfig = res.data.frontendConfig.find((item) => {
            return item.key === "homepage";
          });
          localStorage.setItem("theme", res.data.theme || "");
          localStorage.setItem(
            "frontendConfig",
            frontendConfig ? JSON.stringify(frontendConfig) : ""
          );
          localStorage.setItem(
            "configurationArr",
            JSON.stringify(res.data.frontendConfig)
          );
          this.initConfig();
        }
      });
    },
    initConfig() {
      /* 
        手机号+验证码 is_allow_telephone_verification_code_login 1
        手机号+密码   is_allow_telephone_password_login 2
        邮箱+验证码   is_allow_email_verification_code_login 3
        邮箱+密码     is_allow_email_password_login 4
        身份证+密码   is_allow_id_number_password_login 5
        注册方式:手机号+验证码  is_allow_telephone_register
        注册方式:邮箱+验证码    is_allow_email_register
      */
      let configurationArr = JSON.parse(
        localStorage.getItem("configurationArr")
      );
      if (configurationArr.length > 0) {
        configurationArr.forEach((item) => {
          switch (item.key) {
            case "is_open_register":
              this.canRegister = item.value === "true" ? true : false;
              break;
            case "is_allow_telephone_verification_code_login":
              if (item.value === "false") {
                let index = this.loginMethodList.indexOf("1");
                this.loginMethodList.splice(index, 1);
              }
              break;
            case "is_allow_telephone_password_login":
              if (item.value === "false") {
                let index = this.loginMethodList.indexOf("2");
                this.loginMethodList.splice(index, 1);
              }
              break;
            case "is_allow_email_verification_code_login":
              if (item.value === "false") {
                let index = this.loginMethodList.indexOf("3");
                this.loginMethodList.splice(index, 1);
              }
              break;
            case "is_allow_email_password_login":
              if (item.value === "false") {
                let index = this.loginMethodList.indexOf("4");
                this.loginMethodList.splice(index, 1);
              }
              break;
            case "is_allow_id_number_password_login":
              if (item.value === "false") {
                let index = this.loginMethodList.indexOf("5");
                this.loginMethodList.splice(index, 1);
              }
              break;
            default:
              break;
          }
        });
        this.handleLoginTxt();
      }
    },
    handleLoginTxt() {
      let result = "手机号码/邮箱/身份证号码";
      if (
        !this.loginMethodList.includes("1") &&
        !this.loginMethodList.includes("3")
      ) {
        //密码
        result = this.getMethodTxt();
        this.loginMethodConfig = "password";
      } else if (
        !this.loginMethodList.includes("2") &&
        !this.loginMethodList.includes("4") &&
        !this.loginMethodList.includes("5")
      ) {
        //验证码
        result = this.getMethodTxt();
        this.loginMethodConfig = "code";
        this.place = "Verification";
      } else {
        //验证码+密码
        result = this.getMethodTxt();
        this.loginMethodConfig = "all";
      }
      this.logintxt = result;
    },
    getMethodTxt() {
      let hasphone,
        hasemail,
        hasidentifycard = "";
      if (
        this.loginMethodList.includes("1") ||
        this.loginMethodList.includes("2")
      )
        hasphone = "手机号码";
      if (
        this.loginMethodList.includes("3") ||
        this.loginMethodList.includes("4")
      )
        hasemail = "邮箱";
      if (this.loginMethodList.includes("5")) hasidentifycard = "身份证号码";
      let arr = [hasphone, hasemail, hasidentifycard].filter((item) => {
        return !!item;
      });
      return arr.join("/");
    },
    toRouter(path) {
      const query = { ...this.$route.query };
      this.$router.push({ path, query });
    },
    SwitchingMethod(val) {
      this.place = val;
    },
    setVisible() {
      if (this.isVisible === "password") {
        this.isVisible = "text";
        return 0;
      }
      this.isVisible = "password";
    },
    setCountdown() {
      this.countdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          this.Countdown = 0;
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    inputFocus() {
      const height = document.documentElement.clientHeight;
      if (this.clientHeight > height) {
        let vanField = document.querySelectorAll(".vanField");
        let total = vanField[0].offsetTop - 20;
        document.documentElement.scrollTop = total;
      } else {
        document.documentElement.scrollTop = 0;
      }
      this.clientHeight = document.documentElement.clientHeight;
    },
    postAccountGetAuthCode() {
      if (this.Countdown > 0) return;
      const data = { account: this.phone, type: 1 };
      if (isPhone.test(this.phone) || isEmail.test(this.phone)) {
        this.Countdown = 60;
        this.setCountdown();
        this.$sensors.track("UM_Click_Verification _Code_Button");
        this.$api.usercenter.getAuthCodeCommon({ data }).then((res) => {
          if (res.data) {
            this.$store.state.userInfo.phone = this.phone;
            sessionStorage.setItem("WdPhone", this.phone);
          }
        });
      } else {
        this.$notify({
          type: "warning",
          message: this.placeholderTxt + "格式有误,请检查一下吧",
        });
      }
    },
    async handleLogin() {
      let loginSuc = false;
      if (!this.protocol) return;
      let method =
        this.place === "password"
          ? "postAccountLogin"
          : "postAccountLoginByAuthCode";
      const data = {
        account: this.phone,
        password: md5(this.password),
        authCode: this.Verification,
      };
      this.$store.state.distributionId &&
        (data.distributionId = this.$store.state.distributionId);
      if (window.location.href.indexOf("wdgxjz") != -1) {
        var data1 = { ...data };
        data1.phone = data1.account;
        this.$delete(data1, "account");
        if (this.loginMethod) {
          this.$delete(data1, "authCode");
        } else {
          this.$delete(data1, "password");
        }
        await this.$api.usercenterJz
          .postAccountLogin(data1)
          .then(async (res) => {
            if (res.data) {
              localStorage.setItem("token", res.data);
              loginSuc = true;
            } else {
              return false;
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        await this.$api.usercenter[method]({ data })
          .then(async (res) => {
            if (res.data) {
              localStorage.setItem("token", res.data);
              loginSuc = true;
            } else {
              return;
            }
          })
          .catch(() => {
            return false;
          });
      }
      if(!loginSuc){
        return;
      }

      let checkStuLoginRoleParse = {};
      if (agentHost.includes(window.location.hostname)) {
        console.log("代理商");
        checkStuLoginRoleParse = {
          source: 2, //代理商
        };

        await this.$api.usercenter
          .checkStuLoginRole(checkStuLoginRoleParse)
          .then((res) => {
            console.log("学员角色", res, !res.data);
            if (!res.data) {
              Toast({
                message: "该账号没有权限访问,请联系管理员",
                duration: 4000,
              });
              // 保证本地存储最新的
              localStorage.removeItem("token");
              localStorage.removeItem("code");
              localStorage.removeItem("organ");
              localStorage.removeItem("actor");
              localStorage.removeItem("branch");
              localStorage.removeItem("channel");
              localStorage.removeItem("branchResList");
              this.$router.replace({
                path: "/login",
                query: {
                  name: "promotionCenter",
                  type: new Date().getMinutes(),
                },
              });
            } else {
              console.log("返回true");
            }
          });
      } else {
        console.log("非代理商");
        //  checkStuLoginRoleParse = {
        //   source: 1
        // }
      }

      if (agentHost.includes(window.location.hostname)) {
        console.log("代理商逻辑");
      } else {
        // 保证本地存储最新的
        localStorage.removeItem("code");
        localStorage.removeItem("organ");
        localStorage.removeItem("actor");
        localStorage.removeItem("branchResList");
        localStorage.removeItem("branch");
        localStorage.removeItem("channel");
        //分校信息接口，返回信息作用于checkSaveDetail接口请求头，影响返回值
        let branchRes = [];
        try {
          branchRes = await this.$api.account.getFindUserBranchList({});
        } catch (error) {
          if (error) return;
        }
        // const branchRes =  await this.$api.account.getFindUserBranchList({});
        if (branchRes.data && branchRes.data.length) {
          localStorage.setItem('branchResList',JSON.stringify(branchRes.data))
          let dataObj=null;
          branchRes.data.forEach(val=>{
            if(val.branch==this.$route.query.branch){
              dataObj=val;
            }
          })
          if(dataObj){
            localStorage.setItem("code", dataObj.code);
            localStorage.setItem("organ", dataObj.organ);
            localStorage.setItem("actor", dataObj.actor);
            localStorage.setItem("branch", dataObj.branch);
            dataObj.channel && localStorage.setItem("channel", dataObj.channel);
            this.$sensors.login(dataObj.code);
          }else{
            localStorage.setItem("code", branchRes.data[0].code);
            localStorage.setItem("organ", branchRes.data[0].organ);
            localStorage.setItem("actor", branchRes.data[0].actor);
            localStorage.setItem("branch", branchRes.data[0].branch);
            branchRes.data[0].channel &&
              localStorage.setItem("channel", branchRes.data[0].channel);
            this.$sensors.login(branchRes.data[0].code);
          }

          // localStorage.setItem("code", branchRes.data[0].code);
          // localStorage.setItem("organ", branchRes.data[0].organ);
          // localStorage.setItem("actor", branchRes.data[0].actor);
          // localStorage.setItem("branch", branchRes.data[0].branch);
          // branchRes.data[0].channel &&
          //   localStorage.setItem("channel", branchRes.data[0].channel);
          // this.$sensors.login(branchRes.data[0].code);
        }

        // 获取用户信息
        this.getUserInfo();
        // 获取用户id
        this.findUserId();
      }

      setTimeout(() => {
        if (!this.haveSavedDetailFlag) {
          if (localStorage.getItem("theme") == "junmin") {
            this.$notify({
              duration: 3000,
              type: "danger",
              message: "请先完善个人必填信息",
            });
            this.$router.replace("/myProfile?type=fillIn");
          } else if (localStorage.getItem("theme") == "minzheng") {
            this.$router.replace("/editMinzhengProfile?type=fillIn");
          }
        } else if (sessionStorage.getItem("realName")) {
          location.replace("/idcard");
        } else {
          if (this.$route.query.name) {
            let search = null;
            if (window.location.search) {
              search = window.location.search;
            }
            location.replace(this.$route.query.name + search);
          } else {
            this.$router.push({
              path: "/",
            });
            // location.reload(true);
          }
        }
      }, 50);
      // }//
      // });//
      // }//
    },

    getUserInfo() {
      this.$api.usercenter.getAccountUserInfo({}).then((res) => {
        localStorage.setItem("WDuserInfo", JSON.stringify(res.data));
        this.$store.commit("setUserInfo", res.data);
      });
    },
    // 查询用户id
    findUserId() {
      this.$api.usercenter.findUser().then((res) => {
        if (res.code == 0) {
          let subUser = { id: res.data.id };
          localStorage.setItem("subUserInfo", JSON.stringify(subUser));
        }
      });
    },

    backChange() {
      const agentHost =
        "'agent.saas-h5.testing2.wdeduc.com', 'agent.saas-h5.testing3.wdeduc.com', 'agent.fat.wdeduc.com' , 'agent.uat.wdeduc.com' , 'agent.wdeduc.com'";

      if (agentHost.includes(window.location.hostname)) {
        //location.reload();
        this.$router.replace({
          path: "/login",
          query: {
            name: "promotionCenter",
            type: new Date().getMinutes(),
          },
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.protocolStyle {
  // margin-top 48px
  margin-bottom: 24px;
  white-space: nowrap;

  /deep/.van-checkbox {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }

  span, a {
    display: inline-block;
    font-size: 26px !important;
    line-height: 30px;
    margin-top: 6px;
  }

  span {
    color: #666666;
  }

  a {
    color: #3760FF;
  }
}

.Login {
  background-color: #FFFFFF;
  min-height: 100vh;
}

.LoginMethod {
  padding-left: 32px;
  padding-top: 122px;

  .Method {
    padding: 0 32px;
    font-size: 44px;
    font-weight: 900;
    color: #BBBBBB;
    position: relative;

    span {
      width: 48px;
      height: 8px;
      display: inline-block;
      background-color: #3660FF;
      border-radius: 4px;
      position: absolute;
      margin-left: -24px;
      bottom: -24px;
      left: 50%;
    }

    &.highlight {
      color: #333333;
    }
  }

  .cutApart {
    width: 2px;
    height: 44px;
    margin-top: 10px;
    border-radius: 1px;
    background-color: #D7D7D7;
  }
}

.EditingArea {
  width: 622px;
  padding-top: 55px;
  position: relative;
  margin-left: -311px;
  left: 50%;

  li {
    padding: 1px 0;
    height: 122px;
    overflow: hidden;
    border-bottom: 1px solid #D7D7D7;

    .passwordStyle {
      width: 548px;
      display: inline-block;
    }

    .VisibleCutApart {
      margin: 0 24px;
      width: 2px;
      height: 32px;
      border-radius: 1px;
      background-color: #D7D7D7;
    }

    .Visible {
      display: inline-block;

      img {
        width: 48px;
      }
    }
  }

  li.passwordInputWrapper {
    display: flex;
    align-items: center;
  }

  li.authcodeInputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .van-cell {
    padding: 0;
    height: 58px;
    line-height: 58px;
    font-size: 18px;
    background: transparent;
  }
}

.OperatingSpace {
  width: 622px;
  position: relative;
  margin-left: -311px;
  left: 50%;

  span {
    color: #333333;
    font-size: 30px;
  }

  .van-button {
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin-top: 32px;
    margin-bottom: 24px;

    span {
      color: #FFFFFF;
      font-size: 16px;
    }
  }

  .noneVanButton {
    background-color: #C0D5FF;
    border: 1px solid #C0D5FF;
  }

  .van-button--info {
    background-color: linear-gradient(136deg, #227FFF 0%, #316FFF 100%);
    border: 1px solid linear-gradient(136deg, #227FFF 0%, #316FFF 100%);
  }
}

.VerificationStyle {
  width: 420px;
}

.getVerificationStyle {
  color: #333333;
  font-size: 30px;
  // padding 40px 0
}

.botton-btn {
  display: flex;

  .item {
    flex: 1;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }
}
</style>
